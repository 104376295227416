
import Vue from 'vue';
import { mapActions } from 'vuex';

import { Routes } from '@/router/routes';
import { Services, Models } from '@/injectables/tokens';
import { AuthServiceContract, EnvServiceContract } from '@/injectables';

export default Vue.extend({
  name: 'ResetPassword',

  useInjectable: [Services.Auth, Services.LocalStorage, Services.Env, Models.C360],

  data(): {
    error: string | undefined;
    repeatPassword: string;
    password: string;
    loading: boolean;
    passwordMinLength: number;
    tokenLoading: boolean;
    showPasswordRepeted: boolean;
    showPassword: boolean;
  } {
    return {
      passwordMinLength: 10,
      error: '',
      repeatPassword: '',
      password: '',
      loading: false,
      tokenLoading: false,
      showPasswordRepeted: false,
      showPassword: false,
    };
  },
  watch: {
    repeatPassword() {
      this.error = undefined;
    },
    password() {
      this.error = undefined;
    },
  },
  computed: {
    user() {
      const { user_id: userId } = this.$route.query;
      return userId;
    },
    token() {
      const { token } = this.$route.query;
      return token;
    },
  },

  methods: {
    ...mapActions(['showSnackbar']),
    async saveNewPassword() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form = this.$refs.form as any;
      this.error = undefined;

      if (!form.validate()) return;
      if (this.password !== this.repeatPassword) return (this.error = 'Password and Confirm Password must match');

      this.loading = true;

      const { isErr, unwrap } = await (this.authService as AuthServiceContract).updatePassword(
        this.password,
        this.user,
        this.token,
      );

      this.loading = false;

      if (isErr()) {
        this.loading = false;
        await this.showSnackbar({
          content: "Can't change password at this time",
          color: 'error',
        });
        return;
      }

      const result = unwrap();

      if (!result) {
        await this.showSnackbar({
          content: "Can't change password at this time",
          color: 'error',
        });
        return;
      }

      await this.showSnackbar({ content: 'Your password was changed', color: 'success' });
      return this.$router.replace({ name: Routes.Login });
    },
    currentEnv() {
      return (this[Services.Env] as EnvServiceContract).getEnvironmentName(true);
    },
  },
});
