var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[_c('v-main',{staticClass:"loginpage",attrs:{"id":"login"}},[_c('header',{staticClass:"loginpage__header"},[_c('img',{staticClass:"loginpage-logo",attrs:{"src":require('@/assets/compulse.svg'),"alt":"logo"}})]),_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{staticClass:"page-content",attrs:{"xs12":"","sm8":"","md6":"","lg6":""}},[_c('v-card',{staticClass:"form-card d-flex form__wrapper",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pa-0 pt-1 inputs__wrapper"},[_c('v-form',{ref:"form",staticClass:"mt-4"},[_c('v-text-field',{staticClass:"email__input",attrs:{"name":"password","label":"New password","rules":[
                    v => !!v || 'Password is required',
                    v => v.length >= _vm.passwordMinLength || `Password must have ${_vm.passwordMinLength} characters`,
                  ],"placeholder":" ","persistent-placeholder":"","debounce-search":0,"color":"black","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"hint":`At least ${_vm.passwordMinLength} characters`,"counter":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$refs.password.focus.apply(null, arguments)},"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{ref:"password",staticClass:"password__input",attrs:{"name":"repeat password","label":"Confirm password","id":"password","autocomplete":"new-current-password","placeholder":" ","persistent-placeholder":"","rules":[
                    v => !!v || v.length >= _vm.passwordMinLength || 'Repeated password is required',
                    v => v.length >= _vm.passwordMinLength || `Password must have ${_vm.passwordMinLength} characters`,
                  ],"debounce-search":0,"color":"black","append-icon":_vm.showPasswordRepeted ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPasswordRepeted ? 'text' : 'password',"hint":`At least ${_vm.passwordMinLength} characters`,"counter":""},on:{"click:append":function($event){_vm.showPasswordRepeted = !_vm.showPasswordRepeted}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.saveNewPassword.apply(null, arguments)}},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}})],1),(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1),_c('v-card-actions',{staticClass:"d-flex buttons__wrapper px-8"},[(_vm.$store.getters.currentUser)?_c('p-button',{attrs:{"variant":"text","to":{ name: 'home' }}},[_vm._v("dashboard")]):_vm._e(),_c('v-spacer'),_c('p-button',{staticClass:"mb-2 signin-btn",attrs:{"block":"","border-radius":"large","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.saveNewPassword.apply(null, arguments)}}},[_vm._v("Save a new password")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }